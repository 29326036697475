import BedIcon from '../../../icons/bed.png';
import NumberInput from '../../NumberInput';

export default function BedroomsFilter(props: {
  bedrooms: number;
  setBedrooms: (bedrooms: number) => void;
}) {
  return (
    <>
      <div className="grow">
        <img
          className="w-5 py-3 px-1 inline mr-1"
          src={BedIcon}
          alt="Slaapkamers"
        />
        <span className="text-sm">Slaapkamers</span>
        <NumberInput
          min={1}
          value={props.bedrooms}
          onChange={(val) => {
            props.setBedrooms(val);
          }}
        />
      </div>
    </>
  );
}
