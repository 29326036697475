import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import CrossIcon from './icons/cross.png';
import 'animate.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSliders } from '@fortawesome/free-solid-svg-icons';
import { IFilterOptions, Orientations } from './common/types';
import { price } from './util/format';
import { isMobile } from 'react-device-detect';

export default function RightPanel(props: {
  children: Element | JSX.Element;
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  filterOptions?: IFilterOptions;
}) {
  const [maximized, setMaximized] = useState(true);
  const navigate = useNavigate();
  const [rightStyle, setRightStyle] = useState<{} | undefined>({
    right: !isMobile ? '0px' : '-500px',
  });

  useEffect(() => {
    const rightStyle = !isMobile
      ? {
          right: !isMobile ? '0px' : '-500px',
        }
      : {
          right: props.isOpen ? '0px' : '-500px',
        };
    setRightStyle(rightStyle);
  }, [props.isOpen]);

  // className={
  //   'xs:hidden xs:absolute xs:top-0 xs:h-full xs:w-full md:block md:w-[350px] md:fixed md:right-0 bg-[#fdfdfd]'
  //   // 'xs:block  md:w-full xs:w-full sm:w-full lg:w-full xl:w-[350px] xs:w-[280px] h-full duration-750 transition-all fixed right-0 bg-[#fdfdfd] border border-slate-100 dark:bg-black z-[100]'
  // }

  return (
    <>
      <nav className="bg-white border-gray-200 px-2 lg:px-4 py-2.5 rounded dark:bg-gray-900  xs:block lg:hidden">
        <div className="flex flex-wrap items-center justify-between mx-auto">
          <div className="flex flex-col">
            <div className="flex space-x-2 items-center font-semibold text-sm">
              <span>{price(props?.filterOptions?.minPrice ?? 0)}</span>
              <span>-</span>
              <span>{price(props?.filterOptions?.maxPrice ?? 0)}</span>
            </div>
            <div className="flex space-x-2 items-center text-sm text-slate-500">
              <span>{`${props?.filterOptions?.bedrooms} slpk`}</span>
              <span>•</span>
              <span>{`${props?.filterOptions?.bathrooms} badk`}</span>
              {props?.filterOptions?.orientation !== 'Geen terras' && (
                <>
                  <span>•</span>
                  <span>terras</span>
                </>
              )}
            </div>
          </div>
          <button
            data-collapse-toggle="navbar-default"
            type="button"
            className="inline-flex items-center p-2 ml-3 text-sm text-gray-500 rounded-lg lg:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
            onClick={() => props.setIsOpen(true)}
          >
            <span className="sr-only">Open main menu</span>
            <FontAwesomeIcon icon={faSliders} size="xl" />
          </button>
        </div>
      </nav>

      <div className="z-[1000]">
        <div
          className={
            'xs:fixed xs:top-0 lg:w-full xs:w-full sm:w-full lg:w-full xl:w-[350px] xs:w-[280px] h-full duration-750 transition-all fixed right-0 bg-[#fdfdfd] border border-slate-100 dark:bg-black z-[100] xs:block  lg:w-full xs:w-full lg:w-full lg:w-full xl:w-[350px] xs:w-[280px] h-full duration-750 transition-all fixed right-0 bg-[#fdfdfd] border border-slate-100 dark:bg-black z-[100]'
          }
          style={rightStyle}
        >
          <nav className="lg:hidden bg-slate-100 border-gray-200 px-2 lg:px-4 py-2.5 rounded dark:bg-gray-900">
            <div className="flex flex-wrap items-center justify-between mx-auto px-2 py-2.5">
              <button
                type="button"
                className="inline-flex items-center"
                onClick={() => props.setIsOpen(false)}
              >
                <img className="w-6 h-6" src={CrossIcon} alt="cross" />
              </button>
              <div className="flex items-center">Filters</div>
              <div className="spacer"></div>
            </div>
          </nav>

          <div
            className={
              'absolute top-[27px] transition-all duration-750 ' +
              (maximized ? 'left-[-16px]' : 'left-[55px] ')
            }
          >
            {/* <button className={"w-[32px] h-[32px] bg-[#F2F2F2] rounded-full flex shadow duration-500 transition-all z-[1000]" + (maximized ? '' : 'rotate-180')} onClick={(e) => { e.preventDefault(); setMaximized(!maximized); }}>
                <ArrowRightIcon className="w-5 h-5 m-auto" />
            </button> */}
          </div>
          {maximized ? (
            <div className="flex flex-col xl:divide-y-[1px] divide-black h-full">
              <div className="xs:hidden lg:block lg:p-[30px] xs:invisible md:invisible lg:invisible xl:visible">
                <div className="text-2xl font-thin font-serif animate__animated animate__fadeIn animate">
                  <button onClick={() => navigate('/')}>Stadshave</button>
                </div>
                <div className="font-serif text-sm animate__animated animate__fadeIn animate__slower">
                  Hulst, Nederland
                </div>
              </div>

              {props.children}
            </div>
          ) : (
            <div className="flex flex-col mt-[70px] text-center">
              <div className="font-bold text-sm">Toon filters</div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
