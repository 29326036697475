export enum Availabilities {
    ALL = 'ALL',
    TRUE = 'TRUE',
    OPTION = 'OPTION',
    FALSE = 'FALSE',
}

export enum AvailabilityLabels {
    ALL = 'Alle',
    TRUE = 'Beschikbaar',
    OPTION = 'In optie',
    FALSE = 'Niet beschikbaar',
}

export const AvailabilitiesList: {
    value: string;
    label: string;
  }[] = Object.entries(Availabilities)
    .map(([value]) => { 
        const label: string = AvailabilityLabels[value as keyof typeof AvailabilityLabels]; 
        return {value: value, label: label} 
    });