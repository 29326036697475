import Slider from '@mui/material/Slider';
import Box from '@mui/material/Box';
import { price as priceFormat } from '../../../util/format';
import EuroIcon from '../../../icons/euro.png';

export default function PriceFilter(props: {
  minPrice: number;
  maxPrice: number;
  priceRangeFrom: number;
  priceRangeTo: number;
  setPrice: (minPrice: number, maxPrice: number) => void;
}) {
  const handlePriceChange = (event: Event, newValue: any | any[]) => {
    props.setPrice(newValue[0], newValue[1]);
  };

  return (
    <>
      <div className="grid grid-cols-2 justify-items-stretch">
        <div className="justify-self-start">
          <span className="font-bold text-sm ">Prijs</span>
        </div>
        <div className="justify-self-end">
          <span className="text-sm">
            <span className="pr-2">{priceFormat(props.minPrice)}</span>
            <span>-</span>
            <span className="pl-2">{priceFormat(props.maxPrice)}</span>
          </span>
        </div>
      </div>

      <div className="px-5">
        <Box sx={{ width: '100%' }}>
          <Slider
            min={props.priceRangeFrom}
            max={props.priceRangeTo}
            value={[props.minPrice, props.maxPrice]}
            onChange={handlePriceChange}
          />
        </Box>

        <div className="xl:hidden flex space-x-2">
          <div className="basis-1/2">
            <span className="text-sm text-slate-500">Min</span>
            <div className="w-full h-[34px] border-sky-500 border-[1px] shadow-inner shadow-sky-100 rounded">
              <div className="text-center pt-1.5 text-sky-500 shadow-inner shadow-sky-100">
                {priceFormat(props.minPrice)}
              </div>
            </div>
          </div>
          <div className="basis-1/2">
            <span className="text-sm text-slate-500">Max</span>
            <div className="w-full h-[34px] border-sky-500 border-[1px] shadow-inner shadow-sky-100 rounded">
              <div className="text-center pt-1.5 text-sky-500 shadow-inner shadow-sky-100">
                {priceFormat(props.maxPrice)}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
