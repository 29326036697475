import * as PIXI from 'pixi.js';
import Mapping from './mapping';
import { Model } from './model';

export interface RotatingViewConfig {
  cover?: boolean;
  keepSelection?: boolean;
}

export default class RotatingView {
  app: PIXI.Application;
  mainContainer: PIXI.Container;

  view: HTMLCanvasElement;
  model: Model;
  initX?: number;
  initFrame: number | undefined;

  onHover?: (mapping: Mapping | null) => void;
  onClick?: (mapping: Mapping) => void;
  config: RotatingViewConfig;

  constructor(
    view: HTMLCanvasElement,
    imageUrls: any[],
    mappingsUrls: any[],
    mappings: any[],
    config: RotatingViewConfig,
    onRotation: (index: number) => void,
    cb?: () => void,
    progressCB?: (prog: number) => void,
  ) {
    this.config = config;

    PIXI.settings.MIPMAP_TEXTURES = PIXI.MIPMAP_MODES.OFF;
    PIXI.settings.SCALE_MODE = PIXI.SCALE_MODES.NEAREST;

    view.draggable = false;

    this.app = new PIXI.Application({ view });
    this.mainContainer = new PIXI.Container();
    this.app.stage.addChild(this.mainContainer);
    // Change background color of the renderer in the app
    this.app.renderer.backgroundColor = 0xffffff;

    this.view = view;

    this.model = new Model(
      imageUrls,
      mappingsUrls,
      mappings,
      this.config,
      onRotation,
      () => {
        this.mainContainer.addChild(this.model);

        this.resize();

        if (cb) cb();
      },
      progressCB
    );
    // this.model.on('hover', (mappng) => {
    //   if (this.onHover) this.onHover(mappng);
    // });
    // this.model.on('clear', (mappng) => {
    //   if (this.onHover) this.onHover(null);
    // });
    // this.model.on('click', (mapping) => {
    //   if (this.onClick) this.onClick(mapping);
    // });

    this.resize();
    this.animate(0);
  }

  animate(time: number) {
    this.resize();
    requestAnimationFrame(this.animate.bind(this));
  }

  prevWidth: number = 0;
  prevHeight: number = 0;

  resize() {
    const newWidth = this.app.view.clientWidth * window.devicePixelRatio || 800;
    const newHeight =
      this.app.view.clientHeight * window.devicePixelRatio || 600;

    this.app.renderer.resize(newWidth, newHeight);

    const bounds = this.mainContainer.getLocalBounds();

    if (bounds.width !== 0 && bounds.height !== 0) {
      const s = this.config.cover
        ? Math.max(newHeight / bounds.height, newWidth / bounds.width)
        : Math.min(newHeight / bounds.height, newWidth / bounds.width);

      this.mainContainer.scale.set(s);
      this.mainContainer.x = newWidth / 2;
      this.mainContainer.y = newHeight / 2;
    }

    this.prevWidth = newWidth;
    this.prevHeight = newHeight;
  }
}
