import { Route, Routes } from 'react-router-dom';
import FloorPlan from './pages/FloorPlan';
import Home from './pages/Home';
import Unit from './components/Modal/Unit';
import Perspective from './components/Modal/Perspective';

function App() {
  const dark = false;
  const projectName = window.location.host
    .split('.')[0]
    .replace('-staging', '')
    .replace('showcase', 'demo');

  return (
    <div
      className={
        'flex flex-col h-screen overflow-hidden ' + (dark ? 'dark' : '')
      }
    >
      <Routes>
        <Route
          path="/"
          element={<Home dark={dark} projectName={projectName} />}
        >
          {/* <Route path="/floor/:id" element={<FloorPlan />} /> */}
          <Route path="unit/:unit_id" element={<Unit />} />
          <Route path="perspective" element={<Perspective />} />
        </Route>
      </Routes>
    </div>
  );
}

export default App;
