import BathIcon from '../../../icons/bath.png';
import NumberInput from '../../NumberInput';

export default function BathroomsFilter(props: {
  bathrooms: number;
  setBathrooms: (bathrooms: number) => void;
}) {
  return (
    <>
      <div className="grow">
        <img
          className="w-5 py-3 px-1 inline mr-1"
          src={BathIcon}
          alt="Badkamers"
        />
        <span className="text-sm">Badkamers</span>
        <NumberInput
          min={1}
          value={props.bathrooms}
          onChange={(val) => {
            props.setBathrooms(val);
          }}
        />
      </div>
    </>
  );
}
