export enum Availabilities {
    ALL = 'ALL',
    TRUE = 'TRUE',
    OPTION = 'OPTION',
    FALSE = 'FALSE',
}

export enum AvailabilityLabels {
    ALL = 'Alle',
    TRUE = 'Beschikbaar',
    OPTION = 'In optie',
    FALSE = 'Niet beschikbaar',
}

export const AvailabilitiesList: {
    value: string;
    label: string;
  }[] = Object.entries(Availabilities)
    .map(([value]) => { 
        const label: string = AvailabilityLabels[value as keyof typeof AvailabilityLabels]; 
        return {value: value, label: label} 
    });

export function fetchAvailability(key: keyof typeof Availabilities) {
    return Availabilities[key];
}

export enum Orientations {
    ALL = '',
    NORTH = 'North',
    NORTHEAST = 'North-east',
    EAST = 'East',
    SOUTHEAST = 'South-east',
    SOUTH = 'South',
    SOUTHWEST = 'South-west',
    WEST = 'west',
    NORTHWEST = 'North-west',
    NONE = 'Geen terras',
}

export const OrientationLabels: Record<string, string> = {
    ALL: 'All', 
    NORTH: 'Noord', 
    NORTHEAST: 'Noord-oosten',
    EAST: 'Oost', 
    SOUTHEAST: 'Zuid-oosten',
    SOUTH: 'Zuid', 
    SOUTHWEST: 'Zuid-westen',
    WEST: 'West', 
    NORTHWEST: 'Noord-west',
    NONE: 'Geen terras',
};

export const OrientationsList: {
    value: string;
    label: string;
  }[] = Object.entries(Orientations)
    .map(([value]) => { 
        const label: string = OrientationLabels[value as keyof typeof OrientationLabels]; 
        return {value: value, label: label} 
    });

export function fetchOrientation(key: keyof typeof Orientations) {
    return Orientations[key];
}

export interface IFilterOptions {
    minPrice: number;
    maxPrice: number;
    priceRangeFrom: number;
    priceRangeTo: number;
    availability: Availabilities;
    orientation: Orientations;
    minSurface: number;
    maxSurface: number;
    surfaceRangeFrom: number;
    surfaceRangeTo: number;
    bedrooms: number;
    bathrooms: number;
  }

export const DefaultFilterOptions: IFilterOptions = {
    minPrice: 0,
    maxPrice: 999999,
    priceRangeFrom: 0,
    priceRangeTo: 999999,
    availability: Availabilities.ALL,
    orientation: Orientations.ALL,
    minSurface: 0,
    maxSurface: 999,
    surfaceRangeFrom: 0,
    surfaceRangeTo: 999,
    bedrooms: 1,
    bathrooms: 1,
}


export interface IUnit {
    _id: string;
    unit: string;
    terras?: number;
    terrace_orientation?: Orientations;
    surface?: number;
    project_id?: string;
    kuula_link?: string;
    images?: any;
    color?: string;
    bedrooms?: number;
    bathrooms?: number;
    available?: string;
    'Modified date'?: number;
    'Created Date'?: number;
    'Created By'?: string;
}

export interface IUnits extends Array<IUnit>{} 
