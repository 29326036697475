import Modal from 'react-modal';
import { useNavigate, useLocation } from 'react-router-dom';
import BackButton from '../../components/BackButton';

Modal.setAppElement('#root');

export default function Perspective() {
  const search = useLocation().search;
  const link: string | null = new URLSearchParams(search).get('l');
  const navigate = useNavigate();

  return (
    <>
      <Modal isOpen={true}>
        <BackButton click={() => navigate('/')} />

        {link && (
          <div className="flex h-full">
            <div className="grow bg-black h-full">
              <div className="relative w-full h-full">
                <div className="absolute w-full h-full flex"></div>
                <iframe
                  className="w-full h-full absolute"
                  title="3d view"
                  src={link}
                />
              </div>
            </div>
          </div>
        )}
      </Modal>
    </>
  );
}
