export function price(num: number, cents: boolean = false): string {
  let str = `${cents ? num : Math.round(num)}`;

  const digits: string[] = [];

  while (str.length > 3) {
    const digit = str.slice(-3);
    str = str.slice(0, -3);
    digits.unshift(digit);
  }

  digits.unshift(str);

  return `€${digits.join(".")}`;
}

export function surface(surface: number): string {

  let area = surface.toString()+"m²"

  return area;
}
