import BathIcon from '../icons/bath.png';
import BedIcon from '../icons/bed.png';
import RullerIcon from '../icons/ruller.png';
import { Tooltip } from 'flowbite-react';
import { useEffect, useState } from 'react';
import FavButton from './FavButton';
import { price } from '../util/format';
import { isMobile } from 'react-device-detect';

export interface DataHeader {
  title: string | JSX.Element | Element;
  field: string;
  sort?: boolean;
  format?: (val: string) => string;
}

export default function DataTable(props: {
  headers: DataHeader[];
  data: { [key: string]: any }[];
  onClick?: (item: any) => void;
  onHover?: (item: any) => void;
  onBlur?: () => void;
}) {
  const [sortBy, setSortBy] = useState<string>('');
  const [sortDir, setSortDir] = useState('asc');

  function sort(by: string) {
    if (by === sortBy) {
      setSortDir(sortDir === 'asc' ? 'desc' : 'asc');
    } else {
      setSortBy(by);
      setSortDir('asc');
    }
  }

  function click(item: any) {
    if (props.onClick) props.onClick(item);
  }

  function pointerEnter(item: any) {
    if (props.onHover) props.onHover(item);
  }

  function pointerLeave() {
    if (props.onBlur) props.onBlur();
  }

  function convertAvailableToIcon(input: String) {
    if (input === 'TRUE') {
      return (
        <Tooltip content={'Beschikbaar'}>
          <div className={'text-[#3BBE60]'}>◉</div>
        </Tooltip>
      );
    }
    if (input === 'OPTION') {
      return (
        <Tooltip content={'In optie'}>
          <div className={'text-[#FFA500]'}>◉</div>
        </Tooltip>
      );
    }
    if (input === 'FALSE') {
      return (
        <Tooltip content={'Verkocht'}>
          <div className={'text-[#FF0000]'}>◉</div>
        </Tooltip>
      );
    } else {
      return input;
    }
  }

  const sortedData = props.data
    .map((unit) => {
      if (!unit.images) {
        unit.images = [
          'https://images.adsttc.com/media/images/5be3/3a40/08a5/e549/e300/0315/newsletter/42442.jpg?1541618191',
        ];
      }
      return unit;
    })
    .sort((a, b) =>
      sortBy !== ''
        ? (a[sortBy] > b[sortBy] ? 1 : -1) * (sortDir === 'asc' ? 1 : -1)
        : 0
    );

  const tableScroll = function (event: any) {
    var tbl = event.target.querySelector('table');
    var scrollAmt = event.target.scrollTop;
    var totalRowHeight = 0,
      viewTolerance = 1 / 3, //if only 1/3 of row is showing highlight next row
      rowHeight;

    for (var i = 0; i < tbl.rows.length; i++) {
      totalRowHeight += rowHeight = tbl.rows[i].offsetHeight;
      if (totalRowHeight > scrollAmt) {
        //if row doesn't meet viewTolerance requirement highlight next row
        var rowIndex =
          totalRowHeight - scrollAmt < rowHeight * viewTolerance ? i + 1 : i;
        pointerEnter(sortedData[rowIndex]);
        break;
      }
    }
  };

  return (
    <div
      className="h-full overflow-scroll xs:pb-[200px] lg:pb-0 "
      onScroll={tableScroll}
    >
      <table
        id="estates-result-table"
        className="table-auto w-full divide-y divide-black mt-3 relative"
      >
        <tbody className="divide-y">
          {sortedData.map((item, index) => {
            // console.log(item);
            return (
              <tr
                key={'data_item_' + index}
                className="hover:bg-slate-100 border-hidden cursor-pointer data-item"
                onPointerEnter={() => {
                  if (!isMobile) {
                    pointerEnter(item);
                  }
                }}
                onPointerLeave={() => {
                  if (!isMobile) {
                    pointerLeave();
                  }
                }}
                onClick={() => click(item)}
              >
                <td key={item + '_' + index} className="p-1 text-center">
                  <div className="block relative">
                    <img className="rounded" src={item.images[0]} alt="" />
                    {item.available === 'OPTION' && (
                      <div className="absolute top-2 right-2 border rounded-md bg-slate-100 h-6 w-max">
                        <span className="text-xs p-1">Veel bekenen</span>
                      </div>
                    )}
                  </div>
                  <div className="w-full border-none bg-slate-50 rounded bg-background p-3 mb-2 text-sm xl:visible">
                    <div className="flex justify-between">
                      <div className="flex justify-start">
                        <span className="mr-2 font-bold">{item.unit}</span>
                        <span className="">
                          {convertAvailableToIcon(item.available)}
                        </span>
                      </div>
                      <div className="text-sm">
                        <span className="text-slate-400">
                          {item?.price
                            ? price(item.price)
                            : 'Prijs op aanvraag'}
                        </span>
                      </div>
                    </div>
                    <div className="flex justify-between text-sm">
                      <div className="flex justify-start text-slate-400 cursor-pointer">
                        <div>
                          <img
                            className="w-3.5 inline pb-1 scale-90 filter grayscale contrast-0"
                            src={RullerIcon}
                            alt="Oppervlakte"
                          />
                          <span className="mr-7 ml-1.5">{item.surface}</span>
                        </div>
                        <div>
                          <img
                            className="w-3.5 inline pb-1 filter grayscale contrast-0"
                            src={BedIcon}
                            alt="Slaapkamers"
                          />
                          <span className="mr-7 ml-1.5">{item.bedrooms}</span>
                        </div>
                        <div>
                          <img
                            className="w-3.5 inline pb-1 filter grayscale contrast-0"
                            src={BathIcon}
                            alt="Badkamers"
                          />
                          <span className="mr-7 ml-1.5">{item.bathrooms}</span>
                        </div>
                      </div>
                      {/* <div className="w-5 h-5">
                      <FavButton unitid={item.unit} />
                    </div> */}
                    </div>
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}
