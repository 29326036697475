import { useEffect, useState } from 'react';
import { price } from '../util/format';
import Mapping from '../graphics/mapping';

// Change color in between brackets to change the small round icon next to the min/max floor indicator
export default function FloorPriceInfo(props: { mapping: Mapping }) {
  const [showPrice, setShowPrice] = useState<boolean>(false);

  useEffect(() => {
    setShowPrice(props.mapping.available !== 'FALSE');
  }, [props.mapping.available]);

  return (
    <div className="flex font-bold w-full justify-between text-sm">
      <div className="flex text-[#aa975c]">
        <div className="mr-3 opacity-60 m-auto ">
          <div className="w-4 h-4 bg-[#aa975c] rounded-full"></div>
        </div>
        <div>{props.mapping.name}</div>
      </div>
      {showPrice ? (
        <div className="flex text-[#988651]">
          {props?.mapping?.info?.minPrice && price(props.mapping.info.minPrice)}
        </div>
      ) : null}
    </div>
  );
}
