import TerraceIcon from '../../../icons/bed.png';
import SelectInput from '../../SelectInput';
import { Availabilities, AvailabilitiesList } from '../Types/Availabilities';

export default function AvailabilityFilter(props: {
  setAvailability: (availability: Availabilities) => void;
}) {
  return (
    <>
      <div className="grow">
        <img
          className="w-5 py-3 px-1 inline"
          src={TerraceIcon}
          alt="Terras"
        ></img>
        <span className="text-sm">Beschikbaarheid</span>

        <SelectInput
          values={AvailabilitiesList}
          onChange={(val) => {
            props.setAvailability(val);
          }}
        />
      </div>
    </>
  );
}
