export default function FloorInfo(props: { children: Element | JSX.Element }) {
  return (
    <div
      className="xs:hidden lg:block fixed w-full top-[70px] flex"
      style={{ zIndex: 1000 }}
    >
      <div className="m-auto w-[296px]">
        <div className="h-[36px] w-full bg-[#fdfdfd] rounded-full flex border border-slate-100">
          <div className="m-auto w-full pl-4 pr-4">{props.children}</div>
        </div>
      </div>
    </div>
  );
}
