import { useState, useRef, useEffect, useCallback } from 'react';
import { setEnvironmentData } from 'worker_threads';

export default function useLongPress(onStartLongPress:any, onStopLongPress = () => {}, ms = 300) {
  const [startLongPress, setStartLongPress] = useState(false);
  const event = useRef<any>(null);

  useEffect(() => {
    let timerId: any;
    if (startLongPress) {
      timerId = setTimeout(onStartLongPress(event.current), ms);
    } else {
      clearTimeout(timerId);
    }

    return () => {
      clearTimeout(timerId);
    };
  }, [onStartLongPress, ms, startLongPress]);

  const start = useCallback((e:any) => {
    setTimeout(() => {
      setStartLongPress(true);
      event.current = e;
    }, 100)
  }, []);
  const stop = useCallback(() => {
    setStartLongPress(false);
    onStopLongPress();
  }, []);

  return {
    onMouseDown: start,
    onMouseUp: stop,
    onMouseLeave: stop,
    onTouchStart: start,
    onTouchEnd: stop,
  };
}