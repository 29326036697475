import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import FilterIcon from '../../icons/filters.png';
import DataTable, { DataHeader } from '../DataTable';
import Fold from '../Fold';
import 'animate.css';
import axios from 'axios';
import Alert from '@mui/material/Alert';
import { Snackbar } from '@mui/material';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/solid';
import PriceFilter from './Filters/PriceFilter';
import SurfaceFilter from './Filters/SurfaceFilter';
import BedroomsFilter from './Filters/BedroomsFilter';
import BathroomsFilter from './Filters/BathroomsFilter';
import TerraceFilter from './Filters/TerraceFilter';
import AvailabilityFilter from './Filters/AvailabilityFilter';
import { Availabilities } from './Types/Availabilities';
import {
  DefaultFilterOptions,
  fetchAvailability,
  fetchOrientation,
  IFilterOptions,
} from '../../common/types';

export default function UnitFilter(props: {
  children: Element | JSX.Element;
  filterOptions: IFilterOptions;
  setFilterOptions: Dispatch<SetStateAction<IFilterOptions>>;
  fetchingUnits: boolean;
  filteredData: any;
  onUnitClick: (unit: any) => void;
  setRightPanelIsOpen: Dispatch<SetStateAction<boolean>>;
  onHover?: (unit: any) => void;
  onBlur?: () => void;
  project_id?: any;
}) {
  const [open, setOpen] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<boolean>(false);
  const [filters, setFilters] = useState<boolean>(true);
  const [button, setButton] = useState<boolean>(true);
  const [showTable, setshowTable] = useState<boolean>(false);
  const [unitData, setUnitData] = useState<any>([]);

  const navigate = useNavigate();

  function selectUnit(mapping: any) {
    navigate(`/unit/${mapping.unit}`, {
      state: { mapping, project_id: mapping.project_id },
    });
  }

  function collapseFilters() {
    setFilters(!filters);
  }

  function showFilteredData() {
    setshowTable(!showTable);
  }

  function handleFilters() {
    setButton(!button);
  }

  function resetFilters() {
    props.setFilterOptions(DefaultFilterOptions);
  }

  const setPriceFilter = (minPrice: number, maxPrice: number) => {
    props.setFilterOptions({
      ...props.filterOptions,
      minPrice: minPrice,
      maxPrice: maxPrice,
    });
  };

  const setSurfaceFilter = (minSurface: number, maxSurface: number) => {
    props.setFilterOptions({
      ...props.filterOptions,
      minSurface: minSurface,
      maxSurface: maxSurface,
    });
  };

  const setBedroomsFilter = (bedrooms: number) => {
    props.setFilterOptions({
      ...props.filterOptions,
      bedrooms: bedrooms,
    });
  };

  const setBathroomsFilter = (bathrooms: number) => {
    props.setFilterOptions({
      ...props.filterOptions,
      bathrooms: bathrooms,
    });
  };

  const setTerraceOrientationFilter = (orientation: any) => {
    props.setFilterOptions({
      ...props.filterOptions,
      orientation: fetchOrientation(orientation),
    });
  };

  const setAvailability = (availability: any) => {
    props.setFilterOptions({
      ...props.filterOptions,
      availability: fetchAvailability(availability),
    });
  };

  const buttonTitle = `Bekijk ${props.filteredData.length} Appartementen`;

  return (
    <>
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={() => setOpen(false)}
      >
        <Alert variant="filled" severity="error">
          Error while fetching units data::{errorMsg}
        </Alert>
      </Snackbar>
      <div className="xl:pt-[20px] xl:pl-[15px] xl:pr-[15px] xl:pb-[20px] animate__animated animate__fadeIn animate xs:overflow-scroll">
        <div className="xl:flex lg:hidden sm:hidden lg:hidden xs:hidden justify-between">
          <div onClick={collapseFilters} className="cursor-pointer">
            <img
              className="w-4 inline mr-1 mb-1"
              src={FilterIcon}
              alt="filters"
            />
            <span className="font-semibold text-sm mb-6 ml-1">Filters</span>
            {(!filters || !button) && (
              <span className="font-semibold text-sm mb-6 ml-1">(6)</span>
            )}
          </div>
          <div onClick={handleFilters} className="cursor-pointer">
            {button && (
              <div className="border rounded-full bg-slate-50 h-6 w-20 flex justify-start">
                <span>
                  <ChevronUpIcon className="w-7 h-5 transition-transform pt-0.5 pl-2" />
                </span>
                <span className="text-xs pt-0.5">Verberg</span>
              </div>
            )}
            {!button && (
              <div className="border rounded-full bg-slate-50 h-6 w-20 flex justify-start">
                <span>
                  <ChevronDownIcon className="w-7 h-5 transition-transform pt-0.5 pl-2" />
                </span>
                <span className="text-xs pt-0.5">Toon</span>
              </div>
            )}
          </div>
        </div>

        {filters && button && !showTable && (
          <div className="xs:mx-2 xl:ml-3">
            <div className="block lg:mt-1">
              <PriceFilter
                minPrice={props.filterOptions.minPrice}
                maxPrice={props.filterOptions.maxPrice}
                priceRangeFrom={props.filterOptions.priceRangeFrom}
                priceRangeTo={props.filterOptions.priceRangeTo}
                setPrice={setPriceFilter}
              />
            </div>
            <hr className="xs:my-7 lg:hidden" />
            <div className="block lg:mt-3 lg:mt-1">
              <SurfaceFilter
                minSurface={props.filterOptions.minSurface}
                maxSurface={props.filterOptions.maxSurface}
                surfaceRangeFrom={props.filterOptions.surfaceRangeFrom}
                surfaceRangeTo={props.filterOptions.surfaceRangeTo}
                setSurface={setSurfaceFilter}
              />
            </div>
            <hr className="xs:my-7 lg:hidden" />

            <div className="xs:px-5 lg:px-0 flex space-x-2">
              <BedroomsFilter
                bedrooms={props.filterOptions.bedrooms}
                setBedrooms={setBedroomsFilter}
              />

              <BathroomsFilter
                bathrooms={props.filterOptions.bathrooms}
                setBathrooms={setBathroomsFilter}
              />
            </div>
            <hr className="xs:my-7 lg:hidden" />

            <div className="xs:px-5 lg:px-0 flex space-x-2">
              <TerraceFilter
                setTerraceOrientation={setTerraceOrientationFilter}
              />
              <AvailabilityFilter setAvailability={setAvailability} />
            </div>

            <div className="xs:px-5 lg:px-0 flex flex-col space-x-2 xs:pb-[200px]">
              <div
                onClick={resetFilters}
                className="block text-center mb-7 underline font-normal text-base decoration-solid text-cyan-500"
              >
                Reset Filters
              </div>
              <button
                onClick={() => props?.setRightPanelIsOpen(false)}
                className="block w-full ml-9 h-14 rounded text-center text-lg font-bold mb-3 border bg-black text-white"
              >
                {buttonTitle}
              </button>
            </div>
          </div>
        )}
      </div>

      {props.children}

      {/* <div
        className={
          (showTable
            ? 'xs:visible md:visible lg:visible'
            : 'xs:invisible md:invisible lg:invisible') +
          'pt-[30px] h-full overflow-auto xl:visible'
        }
      >
        <div className="mb-5 mt-2">
          <Fold title={foldTitle} open={true}>
            <div className="ml-[15px] mr-[15px] animate__animated animate__fadeIn animate__slow">
              {!props.fetchingUnits && (
                <DataTable
                  data={props.filteredData}
                  headers={headers}
                  onClick={(item) => {
                    props.onUnitClick(item);
                  }}
                  onHover={(item) => hoverUnit(item)}
                  onBlur={props.onBlur}
                />
              )}
            </div>
          </Fold>
        </div>
      </div> */}
    </>
  );
}
