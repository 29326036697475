export enum PageType {
  mappedView = "mappedView",
  photoGallery = "photoGallery",
  iframe = "iframe",
}

export interface MappedModelConfig {
  rotateDirection?: number;
  images?: {
    numFrames: number;
    loading?: string;
    firstFrame?: number;
    renders: string;
    mappings: string;
  };
  links?: {
    title: string;
    colorKey: string;
    to: string;
    preview?: {
      img: string;
      title?: string;
      available?: string;
    };
    info?: {
      minPrice: number;
      maxPrice: number;
    };
  }[];
  floorNav?: { title: string; to: string }[];
  embedUrl?: string;
}

export interface ConfigType {
  defaultUrl: string;
  rotationStep: number;
  positiveRotationLimiter: number,
  negativeRotationLimiter: number,
  pages: {
    [key: string]: {
      title?: string;
      type: PageType;
      backTo: string | null;
      data: MappedModelConfig;
    };
  };
}

const unitsLinks = [
  {
    title: "Unit 001",
    colorKey: "FF0200",
    to: "demo_360_2",
    preview: {
      img: "images/demo_unit_1/001.jpg",
      title: "Unit 001",
    },
  },
  {
    title: "Unit 002",
    colorKey: "0000FF",
    to: "demo_unit_2",
    preview: {
      img: "images/demo_unit_2/001.jpg",
      title: "Unit 002",
    },
  },
  {
    title: "Unit 003",
    colorKey: "00FF00",
    to: "demo_360_3",
    preview: {
      img: "images/demo_unit_3/001.jpg",
      title: "Unit 003",
    },
  },
  {
    title: "Unit 004",
    colorKey: "FEFF00",
    to: "demo_video",
    preview: {
      img: "images/demo_unit_4/001.jpg",
      title: "Unit 004",
    },
  },
  {
    title: "Unit 005",
    colorKey: "FF01FF",
    to: "demo_unit_5",
    preview: {
      img: "images/demo_unit_5/001.jpg",
      title: "Unit 005",
    },
  },
  {
    title: "Unit 006",
    colorKey: "00FFFF",
    to: "demo_unit_6",
    preview: {
      img: "images/demo_unit_6/001.jpg",
      title: "Unit 006",
    },
  },
  {
    title: "Unit 007",
    colorKey: "",
    to: "demo_unit_1",
    preview: {
      img: "images/demo_unit_1/001.jpg",
      title: "Unit 007",
    },
  },
  {
    title: "Unit 008",
    colorKey: "",
    to: "demo_unit_3",
    preview: {
      img: "images/demo_unit_3/001.jpg",
      title: "Unit 008",
    },
  },
  {
    title: "Unit 009",
    colorKey: "",
    to: "demo_360_1",
    preview: {
      img: "images/demo_unit_2/001.jpg",
      title: "Unit 009",
    },
  },
  {
    title: "Unit 010",
    colorKey: "",
    to: "demo_unit_4",
    preview: {
      img: "images/demo_unit_4/001.jpg",
      title: "Unit 010",
    },
  },
];

const config: ConfigType = {
  defaultUrl: "/view/demo_flyaround",
  rotationStep: 32,
  positiveRotationLimiter: 40,
  negativeRotationLimiter: -40,
  pages: {
    demo_360_1: {
      type: PageType.iframe,
      backTo: "demo_floor_1",
      data: {
        embedUrl:
          "https://kuula.co/share/NlQTT/collection/7kQp3?logo=0&info=0&fs=1&vr=1&sd=1&initload=0&thumbs=1",
      },
    },
    demo_360_2: {
      type: PageType.iframe,
      backTo: "demo_floor_1",
      data: {
        embedUrl:
          "https://kuula.co/share/collection/7kQpN?logo=0&info=0&fs=1&vr=1&sd=1&initload=0&thumbs=1",
      },
    },
    demo_360_3: {
      type: PageType.iframe,
      backTo: "demo_floor_1",
      data: {
        embedUrl:
          "https://kuula.co/share/collection/7kQpT?logo=0&info=0&fs=1&vr=1&sd=1&initload=0&thumbs=1",
      },
    },
    demo_video: {
      type: PageType.iframe,
      backTo: "demo_floor_1",
      data: {
        embedUrl: "images/demo_video/As_vb_Loft_Antwerpen.mp4",
      },
    },
    demo_stylobate: {
      type: PageType.photoGallery,
      backTo: "demo_flyaround",
      data: {
        images: {
          mappings: "",
          numFrames: 1,
          renders: "images/demo_stylobate/{4}.jpg",
          firstFrame: 1,
        },
        links: [],
        rotateDirection: 0,
      },
    },
    demo_unit_1: {
      type: PageType.photoGallery,
      backTo: "demo_floor_1",
      data: {
        images: {
          mappings: "",
          numFrames: 1,
          renders: "images/demo_unit_1/{3}.jpg",
          firstFrame: 1,
        },
        links: [],
        rotateDirection: 1,
      },
    },
    demo_unit_2: {
      type: PageType.photoGallery,
      backTo: "demo_floor_1",
      data: {
        images: {
          mappings: "",
          numFrames: 2,
          renders: "images/demo_unit_2/{3}.jpg",
          firstFrame: 1,
        },
        links: [],
        rotateDirection: 1,
      },
    },
    demo_unit_3: {
      type: PageType.photoGallery,
      backTo: "demo_floor_1",
      data: {
        images: {
          mappings: "",
          numFrames: 2,
          renders: "images/demo_unit_3/{3}.jpg",
          firstFrame: 1,
        },
        links: [],
        rotateDirection: 1,
      },
    },
    demo_unit_4: {
      type: PageType.photoGallery,
      backTo: "demo_floor_1",
      data: {
        images: {
          mappings: "",
          numFrames: 4,
          renders: "images/demo_unit_4/{3}.jpg",
          firstFrame: 1,
        },
        links: [],
        rotateDirection: 1,
      },
    },
    demo_unit_5: {
      type: PageType.photoGallery,
      backTo: "demo_floor_1",
      data: {
        images: {
          mappings: "",
          numFrames: 2,
          renders: "images/demo_unit_5/{3}.jpg",
          firstFrame: 1,
        },
        links: [],
        rotateDirection: 1,
      },
    },
    demo_unit_6: {
      type: PageType.photoGallery,
      backTo: "demo_floor_1",
      data: {
        images: {
          mappings: "",
          numFrames: 1,
          renders: "images/demo_unit_6/{3}.jpg",
          firstFrame: 1,
        },
        links: [],
        rotateDirection: 1,
      },
    },
    demo_unit: {
      type: PageType.photoGallery,
      backTo: "demo_floor_1",
      data: {
        images: {
          mappings: "",
          numFrames: 10,
          renders: "images/demo_unit/{4}.jpg",
          firstFrame: 0,
        },
        links: [],
        rotateDirection: 1,
      },
    },
    havenhuis_1: {
      title: "1ste Etage",
      type: PageType.mappedView,
      backTo: "demo_flyaround",
      data: {
        rotateDirection: 1,
        images: {
          loading: "",
          numFrames: 1,
          firstFrame: 0,
          renders: "images/floor/havenhuis_1/render-0001.jpg",
          mappings: "images/floor/havenhuis_1/mapping-0001.png",
        },
        links: unitsLinks,
        floorNav: [
          { title: "Etage 1", to: "havenhuis_1" },
          { title: "Floor 2", to: "demo_floor_2" },
        ],
      },
    },
    demo_floor_2: {
      title: "2nd floor",
      type: PageType.mappedView,
      backTo: "demo_flyaround",
      data: {
        rotateDirection: 1,
        images: {
          numFrames: 1,
          firstFrame: 0,
          renders: "images/demo_floor_2/render-{4}.jpeg",
          mappings: "images/demo_floor_2/mapping-{4}.png",
        },
        links: unitsLinks,
        floorNav: [
          { title: "Floor 1", to: "demo_floor_1" },
          { title: "Floor 2", to: "demo_floor_2" },
        ],
      },
    },
    demo_flyaround: {
      type: PageType.mappedView,
      backTo: null,
      data: {
        rotateDirection: -1,
        images: {
          loading: "images/demo_flyaround/loading.jpg",
          numFrames: 60,
          firstFrame: 1,
          renders: "images/demo_flyaround/render_{4}.jpg",
          mappings: "images/demo_flyaround/mapping_{4}.png",
        },
        links: [
          {
            title: "Etage 3",
            colorKey: "AD35C0",
            to: "werfhuis_3",
            info: { minPrice: 275000, maxPrice: 315000 },
          },
          {
            title: "Etage 2",
            colorKey: "D99E5C",
            to: "werfhuis_2",
            info: { minPrice: 275000, maxPrice: 315000 },
          },
          {
            title: "Etage 1",
            colorKey: "C03183",
            to: "werfhuis_1",
            info: { minPrice: 275000, maxPrice: 315000 },
          },
          {
            title: "Etage 0",
            colorKey: "5EC8D9",
            to: "werfhuis_0",
            info: { minPrice: 275000, maxPrice: 315000 },
          },
          {
            title: "Etage 4",
            colorKey: "FF5C5C",
            to: "havenhuis_4",
            info: { minPrice: 275000, maxPrice: 315000 },
          },
          {
            title: "Etage 3",
            colorKey: "42A432",
            to: "havenhuis_3",
            info: { minPrice: 275000, maxPrice: 315000 },
          },
          {
            title: "Etage 2",
            colorKey: "F4D3ED",
            to: "havenhuis_2",
            info: { minPrice: 275000, maxPrice: 315000 },
          },
          {
            title: "Etage 1",
            colorKey: "5CD95C",
            to: "havenhuis_1",
            info: { minPrice: 200000, maxPrice: 250000 },
          },
          { title: "Stylobate 2", colorKey: "3535c0", to: "demo_stylobate" },
          { title: "Stylobate 1", colorKey: "bf2e2e", to: "demo_stylobate" },
        ],
      },
    },
  },
};

export default config;
