import 'animate.css';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import FloorInfo from '../../components/FloorInfo';
import FloorPriceInfo from '../../components/FloorPriceInfo';
import MappedModel from '../../components/MappedModel';
import UnitFilter from '../../components/UnitFilter/UnitFilter';
import mappingsData from '../../data/mappings_overwrite.json';
import { price as priceFormat } from '../../util/format';
import Mapping from '../../graphics/mapping';
import Instructions from '../../Instructions';
import RightPanel from '../../RightPanel';
import { Snackbar } from '@mui/material';
import Alert from '@mui/material/Alert';
import { Oval } from 'react-loader-spinner';
import { isMobile } from 'react-device-detect';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHouse } from '@fortawesome/free-solid-svg-icons';
import {
  Availabilities,
  DefaultFilterOptions,
  IFilterOptions,
  IUnits,
} from '../../common/types';
import Fold from '../../components/Fold';
import DataTable, { DataHeader } from '../../components/DataTable';

export default function Mobile(props: { dark: boolean; projectName: any }) {
  const [activeMapping, setActiveMapping] = useState<any>();
  const [selected, setSelected] = useState<Mapping>();
  const [keepSelection, setKeepSelection] = useState<boolean>(false);
  const [projectData, setProjectData] = useState<any>([]);
  const [error, setError] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [open, setOpen] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<boolean>(false);
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
  const [mapping, setMapping] = useState<any>();
  const [loaderVisible, setLoaderVisible] = useState<boolean>(false);
  const [rightPanelIsOpen, setRightPanelIsOpen] = useState<boolean>(false);

  const [units, setUnits] = useState<IUnits>([]);
  const [filteredUnits, setFilteredUnits] = useState<IUnits>([]);
  const [fetchingUnits, setFetchingUnits] = useState<boolean>(true);

  const [filterOptions, setFilterOptions] =
    useState<IFilterOptions>(DefaultFilterOptions);

  const fetchUnits = async function () {
    setFetchingUnits(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/wf/project_units?project_id=${projectData.project._id}`
      );

      setMinMaxPrice(response.data.response.units);
      setMinMaxSurface(response.data.response.units);
      setUnits(() => response.data.response.units);
      setFetchingUnits(false);
    } catch (error: any) {
      setOpen(true);
      setErrorMsg(error.message);
      // setFetchingUnits(false);
    }
  };

  const setMinMaxPrice = (units: any) => {
    const a =
      Math.floor(
        units.reduce(
          (prev: any, current: any) =>
            current.price < prev ? current.price : prev,
          999999
        ) / 100000
      ) * 100000;

    const b =
      Math.ceil(
        units.reduce(
          (prev: any, current: any) =>
            current.price > prev ? current.price : prev,
          0
        ) / 100000
      ) * 100000;

    setFilterOptions((options) => ({
      ...options,
      minPrice: a,
      maxPrice: b,
      priceRangeFrom: a,
      priceRangeTo: b,
    }));
  };

  const setMinMaxSurface = (units: any) => {
    const a =
      Math.floor(
        units.reduce(
          (prev: any, current: any) =>
            current.surface < prev ? current.surface : prev,
          999999
        ) / 10
      ) * 10;

    const b =
      Math.ceil(
        units.reduce(
          (prev: any, current: any) =>
            current.surface > prev ? current.surface : prev,
          0
        ) / 100
      ) * 100;

    setFilterOptions((options) => ({
      ...options,
      minSurface: a,
      maxSurface: b,
      surfaceRangeFrom: a,
      surfaceRangeTo: b,
    }));
  };

  useEffect(() => {
    if (!fetchingUnits) {
      const filteredUnitData = units
        .filter((item: any) => {
          return (
            item.surface >= filterOptions.minSurface &&
            item.surface <= filterOptions.maxSurface &&
            ((item.price >= filterOptions.minPrice &&
              item.price <= filterOptions.maxPrice) ||
              !item?.price) &&
            (filterOptions.availability === Availabilities.ALL ||
              item.available === filterOptions.availability) &&
            item.bedrooms >= filterOptions.bedrooms &&
            item.bathrooms >= filterOptions.bathrooms &&
            (item.terrace_orientation === filterOptions.orientation ||
              filterOptions.orientation === '')
          );
        })
        .sort((a: any, b: any) =>
          a.unit > b.unit ? 1 : b.unit > a.unit ? -1 : 0
        );

      setFilteredUnits(filteredUnitData);
    }
  }, [units, fetchingUnits, filterOptions]);

  useEffect(() => {
    fetchProject();
    // fetch('https://localhost:4000/seller', {
    //   method: 'GET',
    //   mode: 'cors',
    //   headers: {
    //     'Content-Type': 'application/json',
    //   },
    // }).then((response) => console.log(response));
  }, []);

  useEffect(() => {
    fetchUnits();
  }, [projectData]);

  const fetchProject = async function () {
    setLoading(true);
    try {
      const data = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/wf/project_floors?name=${props.projectName}`
      );

      const response = { data: mappingsData };

      setProjectData(response.data.response);
      response.data.response.Floors.length === 0
        ? setError(true)
        : setError(false);
      setLoading(false);
      setOpen(false);
    } catch (error: any) {
      setErrorMsg(error.message);
      setOpen(true);
      setLoading(false);
    }
  };

  const navigate = useNavigate();

  function onHover(mapping: Mapping | null) {
    setActiveMapping(mapping);
  }

  function onClick(mapping: any) {
    const type = mapping?.type;

    if (type === 'perspective') {
      navigate(`perspective?l=${mapping?.link}`);
      return false;
    }

    const link = mapping?.link ? mapping.link : `unit/${mapping?.unit}`;
    setLoaderVisible(true);

    navigate(link, {
      state: { mapping, project_id: projectData.project._id },
    });
  }

  function onFilterUnitHover(unit: any) {
    setKeepSelection(true);
    setSelected({
      color: Number.parseInt(unit.color, 16),
      id: unit.unit,
      name: unit.unit,
      link: '',
    });
  }

  function onFilterUnitBlur() {
    setKeepSelection(false);
    setSelected(undefined);
  }

  function onLoaded() {}

  const showInstructions = localStorage.getItem('instructions_shown') === null;

  function hoverUnit(unit: any) {
    if (onHover) onHover(unit);
  }

  const headers: DataHeader[] = [
    { title: 'Beschikb.', field: 'available', sort: true },
    { title: 'Unit', field: 'unit', sort: true },
    { title: 'Opp. (in m²)', field: 'surface', sort: true },
    {
      title: 'Prijs VON',
      field: 'price',
      sort: true,
      format: (v) => priceFormat(parseFloat(v)),
    },
  ];

  if (showInstructions) {
    localStorage.setItem('instructions_shown', 'true');
  }
  // if (error) {
  //   return <h1>Project can't be found or there is no data.</h1>;
  // }
  // if (open) {
  //   return (
  //     <Snackbar
  //       open={open}
  //       autoHideDuration={6000}
  //       onClose={() => setOpen(false)}
  //       message="Note archived"
  //     >
  //       <Alert variant="filled" severity="error">
  //         Error while fetching project data::{errorMsg}
  //       </Alert>
  //     </Snackbar>
  //   );
  // }
  return (
    <>
      <Oval
        height="80"
        width="80"
        color="#fff"
        secondaryColor="#000"
        ariaLabel="loading"
        strokeWidth={5}
        strokeWidthSecondary={1}
        wrapperClass={'loader-wrapper'}
        visible={loaderVisible}
      />
      {activeMapping != null ? (
        <FloorInfo>
          <FloorPriceInfo mapping={activeMapping} />
        </FloorInfo>
      ) : null}

      {!loading && (
        <RightPanel
          isOpen={rightPanelIsOpen}
          setIsOpen={setRightPanelIsOpen}
          filterOptions={filterOptions}
        >
          <UnitFilter
            onUnitClick={onClick}
            onHover={onFilterUnitHover}
            filteredData={filteredUnits}
            fetchingUnits={fetchingUnits}
            onBlur={onFilterUnitBlur}
            project_id={projectData.project._id}
            filterOptions={filterOptions}
            setFilterOptions={setFilterOptions}
            setRightPanelIsOpen={setRightPanelIsOpen}
          >
            <div
              className={'xs:hidden lg:block pt-[30px] h-full overflow-auto'}
              onScroll={(event) => console.log(event)}
            >
              <div className="mb-5 mt-2">
                <Fold
                  title={`Gefilterde units (${filteredUnits.length})`}
                  open={true}
                >
                  <div className="ml-[15px] mr-[15px] animate__animated animate__fadeIn animate__slow">
                    {!fetchingUnits && (
                      <DataTable
                        data={filteredUnits}
                        headers={headers}
                        onClick={(item) => {
                          onClick(item);
                        }}
                        onHover={(item) => onFilterUnitHover(item)}
                        onBlur={onFilterUnitBlur}
                      />
                    )}
                  </div>
                </Fold>
              </div>
            </div>
          </UnitFilter>
        </RightPanel>
      )}

      <div className="xl:flex h-full w-full">
        <div className="grow xs:h-1/3 xs:flex xs:flex-col xl:h-full">
          {!loading && (
            <MappedModel
              config={{ cover: false, keepSelection: keepSelection }}
              data={projectData}
              onClick={onClick}
              onHover={onHover}
              onLoaded={onLoaded}
              modalIsOpen={modalIsOpen}
              selected={selected}
            />
          )}

          <div className="flex flex-1 items-center px-[15px]">
            <span
              className="font-bold"
              style={{ display: fetchingUnits ? 'none' : 'block' }}
            >
              <FontAwesomeIcon className="pr-[5px]" icon={faHouse} />
              <span className="text-sm">{`Gefilterde units (${filteredUnits.length})`}</span>{' '}
            </span>
          </div>
        </div>

        <div className="xl:hidden grow h-2/3 px-[15px] ">
          {!fetchingUnits && (
            <div className="h-full animate__animated animate__fadeIn animate__slow">
              <DataTable
                data={filteredUnits}
                headers={headers}
                onClick={(item) => {
                  onClick(item);
                }}
                onHover={(item) => onFilterUnitHover(item)}
                onBlur={onFilterUnitBlur}
              />
            </div>
          )}
        </div>

        {showInstructions ? <Instructions /> : null}
      </div>
      <Outlet context={[projectData, setLoaderVisible]} />
    </>
  );
}
