import { isMobile } from 'react-device-detect';
import Desktop from './Home/Desktop';
import Mobile from './Home/Mobile';

export default function Home(props: { dark: boolean; projectName: any }) {
  if (isMobile) {
    return <Mobile {...props}></Mobile>;
  }

  return <Desktop {...props}></Desktop>;
}
