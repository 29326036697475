export default function ModelBackground() {
  return (
    <>
      <div className="flex flex-col h-full w-full absolute">
        <div className="h-1/2 bg-gradient-to-t from-[#EFD4BA] to-[#BD7A4D]"></div>
        <div className="h-1/2 bg-gradient-to-l from-[#03060E] to-[#1E273F]"></div>
      </div>
    </>
  );
}
