import { OrientationsList } from '../../../common/types';
import TerraceIcon from '../../../icons/terrace.png';
import SelectInput from '../../SelectInput';

export default function TerraceFilter(props: {
  setTerraceOrientation: (orientation: any) => void;
}) {
  return (
    <>
      <div className="grow">
        <img
          className="w-5 py-3 px-1 inline"
          src={TerraceIcon}
          alt="Terras"
        ></img>
        <span className="text-sm">Terras oriëntatie</span>

        <SelectInput
          values={OrientationsList}
          onChange={(val) => {
            props.setTerraceOrientation(val);
          }}
        />
      </div>
    </>
  );
}
