import { detect } from 'detect-browser';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter, HashRouter } from 'react-router-dom';
import App from './App';
import UnsupportedBrowser from './components/UnsupportedBrowser';
import './index.css';
import reportWebVitals from './reportWebVitals';
import store from './store';

import 'flowbite';

const browserSupported = true;
// const browserSupported = detect()?.name != 'safari';

ReactDOM.render(
  <Provider store={store}>
    {browserSupported ? (
      <HashRouter>
        <App />
      </HashRouter>
    ) : (
      <UnsupportedBrowser />
    )}
  </Provider>,
  document.getElementById('root')
);

reportWebVitals();
