import Slider from '@mui/material/Slider';
import Box from '@mui/material/Box';
import { surface as surfaceFormat } from '../../../util/format';
import RullerIcon from '../../../icons/ruller.png';

export default function SurfaceFilter(props: {
  minSurface: number;
  maxSurface: number;
  surfaceRangeFrom: number;
  surfaceRangeTo: number;
  setSurface: (minSurface: number, maxSurface: number) => void;
}) {
  const handleSurfaceChange = (event: Event, newValue: any | any[]) => {
    props.setSurface(newValue[0], newValue[1]);
  };

  return (
    <>
      <div className="grid grid-cols-2 justify-items-stretch">
        <div className="justify-self-start">
          <span className="font-bold text-sm ">Oppervlakte</span>
        </div>
        <div className="justify-self-end">
          <span className="text-sm">
            <span className="pr-2">{surfaceFormat(props.minSurface)}</span>
            <span>-</span>
            <span className="pl-2">{surfaceFormat(props.maxSurface)}</span>
          </span>
        </div>
      </div>

      <div className="px-5">
        <Box sx={{ width: '100%' }}>
          <Slider
            min={props.surfaceRangeFrom}
            max={props.surfaceRangeTo}
            value={[props.minSurface, props.maxSurface]}
            onChange={handleSurfaceChange}
          />
        </Box>

        <div className="xl:hidden flex space-x-2">
          <div className="basis-1/2">
            <span className="text-sm text-slate-500">Min</span>
            <div className="w-full h-[34px] border-sky-500 border-[1px] shadow-inner shadow-sky-100 rounded">
              <div className="text-center pt-1.5 text-sky-500 shadow-inner shadow-sky-100">
                {surfaceFormat(props.minSurface)}
              </div>
            </div>
          </div>
          <div className="basis-1/2">
            <span className="text-sm text-slate-500">Max</span>
            <div className="w-full h-[34px] border-sky-500 border-[1px] shadow-inner shadow-sky-100 rounded">
              <div className="text-center pt-1.5 text-sky-500 shadow-inner shadow-sky-100">
                {surfaceFormat(props.maxSurface)}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
