import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import DataMapping from '../data/types/DataMapping';
// import SvgTest from '../graphics/mappings/msvg_001.svg';
import { ReactSVG } from 'react-svg';
import Mapping from '../graphics/mapping';
import RotatingView, { RotatingViewConfig } from '../graphics/rotatingView';
import Loading from '../components/Loading';
import useLongPress from '../util/longpress';
import React from 'react';
import { Model } from '../graphics/model';
import { current } from '@reduxjs/toolkit';
import { isMobile } from 'react-device-detect';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faArrowLeft } from '@fortawesome/free-solid-svg-icons';

export default function MappedModel(props: {
  onHover: (mapping: Mapping | null) => void;
  onClick: (mapping: Mapping) => void;
  onLoaded?: () => void;
  data: any;
  config: RotatingViewConfig;
  modalIsOpen: boolean;
  selected?: any;
}) {
  const [loading, setLoading] = useState(true);
  const [cursor, setCursor] = useState('auto');
  const [loadProgress, setLoadProgress] = useState(0);
  const [view, setView] = useState<RotatingView>();
  const [showInstructions, setShowInstructions] = useState(true);
  const [hideInstructions, setHideInstructions] = useState(false);
  const [showSvg, setShowSvg] = useState(true);
  const [mappingSvg, setMappingSvg] = useState<any>(0);
  const [mouseIsDown, setMouseIsDown] = useState<boolean>(false);

  let lastMapping = useRef<any>();
  let currentAvailability = useRef<any>();

  window.onmousedown = () => {
    setMouseIsDown(true);
  };
  window.onmouseup = () => {
    setMouseIsDown(false);
  };

  useEffect(() => {
    rotatingView.current.model.setMouseIsDown(mouseIsDown);
  }, [mouseIsDown]);

  function lpad(str: string, n: number = 4): string {
    while (str.length < n) str = '0' + str;
    return str;
  }

  const canvasRef = useRef<HTMLCanvasElement>(null);
  const rotatingView = useRef<any>(null);

  const turnaroundDimensions = {
    width: 1920,
    height: 1080,
  };

  const renderedHeight = isMobile
    ? turnaroundDimensions.height *
      (window.innerWidth / turnaroundDimensions.width)
    : 'inherit';

  useLayoutEffect(() => {
    const data = props.data;

    let mpngs: any[] = [];

    if (data.Floors) {
      mpngs = data.Floors.map((l: any) => {
        return {
          name: l.title,
          mappings: l.mappings,
          renders: l.renders,
          color: Number.parseInt(l.color, 16),
          id: l.title,
          link: l.link,
          available: l.available,
          type: l?.type,
          info: {
            minPrice: l.min_price || 0,
            maxPrice: l.max_price || 0,
          },
        };
      });
    }

    if (data.units) {
      mpngs = data.units.map((l: any) => {
        return {
          unit: l.unit,
          images: [...l.images],
          bathrooms: l.bathrooms,
          bedrooms: l.bedrooms,
          terras: l.terras,
          price: l.price,
          surface: l.surface,
          available: l.available,
          type: l?.type,
          color: Number.parseInt(l.color, 16),
          id: l.unit,
          link: `unit/${l._id}`,
          info: {
            minPrice: l.min_price || 0,
            maxPrice: l.max_price || 0,
          },
        };
      });
    }

    function loaded() {
      setLoading(false);
      setShowInstructions(true);
      setTimeout(() => {
        setShowInstructions(false);
        setHideInstructions(true);
      }, 0);
      if (props.onLoaded) props.onLoaded();
    }

    rotatingView.current = new RotatingView(
      canvasRef.current!,
      data.project ? data.project.renders : data.renders,
      data.project ? data.project.mappings : data.mappings,
      mpngs,
      props.config,
      (index) => {
        if (isMobile) {
          index = index * 8;
        }
        setMappingSvg(index);
      },
      loaded,
      (progress) => {
        setLoadProgress(progress);
      }
    );

    // rotatingView.current.onHover = (mapping: Mapping | null) => {
    //   if (mapping) {
    //     setCursor('pointer');
    //   } else {
    //     setCursor('auto');
    //   }
    //   props.onHover(mapping);
    // };

    // rotatingView.current.onClick = (mapping: Mapping) => {
    //   props.onClick(mapping);
    // };
  }, []);

  const hoverUnitEvent = React.useCallback((unit: any) => {
    const unitData: any = props.data.Floors.filter((f: any) => f.unit === unit);
    lastMapping.current = unitData.map((data: any) => {
      return {
        color: data.color,
        name: data.name,
        id: data.unit,
        link: `unit/${data.unit}`,
        available: data.available,
        info: {
          minPrice: data.min_price || 0,
          maxPrice: data.max_price || 0,
        },
      };
    })[0];

    if (!unitData[0]) return;

    switch (unitData[0].available) {
      case 'OPTION':
        currentAvailability.current = 'warning';
        break;
      case 'FALSE':
        currentAvailability.current = 'danger';
        break;
      default:
        currentAvailability.current = 'success';
        break;
    }

    props.onHover(lastMapping.current);
    return;
  }, []);

  useEffect(() => {
    rotatingView.current.model.setHovering(!props.modalIsOpen);
  }, [props.modalIsOpen]);

  useEffect(() => {
    rotatingView.current.model.updateConfig(props.config);
  }, [props.config]);

  useEffect(() => {
    const elements = document.getElementsByClassName('hovering');
    for (let index = 0; index < elements.length; index++) {
      elements[index].classList.remove('hovering');
    }

    if (!props.selected) {
      props.onHover(null);
      return;
    }

    document
      ?.querySelector(`[data-unit="${props?.selected?.id}"]`)
      ?.classList.add('hovering');

    hoverUnitEvent(props?.selected?.id);
  }, [props.selected, hoverUnitEvent]);

  const onLongPress = (e: any) => {
    setShowSvg(false);
    rotatingView.current.model.setAllowRotation(true);
    rotatingView.current.model.setInitialMovement(true);
  };

  const stopLongPress = () => {
    setShowSvg(true);
    rotatingView.current.model.setAllowRotation(false);
    rotatingView.current.model.setInitialMovement(false);
  };

  const longPressEvent = useLongPress(onLongPress, stopLongPress, 1000);

  const onPrepareSVG = React.useCallback((svg: SVGElement) => {
    let resetLastMapping: any;

    svg.addEventListener('mouseover', (event) => {
      clearTimeout(resetLastMapping);
      const target = event.target as HTMLButtonElement;
      const unit: any = target?.dataset.unit;

      if (unit === 'perspective-dot') {
        lastMapping.current = {
          type: 'perspective',
          link: 'https://kuula.co/share/NwrS7?logo=-1&info=0&fs=1&vr=1&sd=1&thumbs=4&inst=0&gcl=1',
        };
        return;
      }

      if (unit && unit !== 'Layer_1') {
        hoverUnitEvent(unit);
        return;
      }

      resetLastMapping = setTimeout(() => {
        lastMapping.current = null;
        props.onHover(null);
      }, 100);
    });

    svg.addEventListener('click', (e) => {
      if (!lastMapping.current) return false;
      props.onClick(lastMapping.current);
    });
  }, []);

  const toNextFrame = function () {
    console.log('time to jump');
    rotatingView.current.model.jumpToNextFrameIteration();
  };

  const toPreviousFrame = function () {
    rotatingView.current.model.jumpToPreviousFrameIteration();
  };

  return (
    <div
      className={`w-full xl:h-full relative hover-${currentAvailability.current}`}
      style={{ zIndex: 0, cursor: cursor, height: renderedHeight }}
    >
      <div className="absolute top-5 right-5 flex space-x-4 z-[2000]">
        <button
          onClick={toPreviousFrame}
          className="bg-[#F9F9F9] h-[40px] w-[40px] rounded-lg"
        >
          <FontAwesomeIcon icon={faArrowLeft} size="xl" />
        </button>
        <button
          onClick={toNextFrame}
          className="bg-[#F9F9F9] h-[40px] w-[40px] rounded-lg"
        >
          <FontAwesomeIcon icon={faArrowRight} size="xl" />
        </button>
      </div>
      <ReactSVG
        src={`/images/main/mappings/svg/Mappings_${mappingSvg}.svg`}
        {...longPressEvent}
        beforeInjection={onPrepareSVG}
      />
      {/* <ReactSVG src="/images/main/mappings/msvg_00.svg" {...longPressEvent} /> */}
      <canvas
        className="w-full xl:h-full"
        style={{ height: renderedHeight }}
        ref={canvasRef}
      ></canvas>

      {loading ? (
        <>
          {props.data.project?.loading_img ? (
            <div
              className={
                'absolute top-0 left-0 w-full h-full flex bg-center bg-no-repeat ' +
                (props.config.cover ? 'bg-cover' : 'bg-contain')
              }
              style={{
                backgroundImage: `url(${props.data.project.loading_img})`,
              }}
            ></div>
          ) : null}
          <div className="absolute top-0 left-0 w-full h-full flex">
            <Loading progress={loadProgress} />
          </div>
        </>
      ) : null}

      {showInstructions ? (
        <div className="fixed w-full h-full top-0 left-0 flex text-sm z-[1000] text-center"></div>
      ) : null}
    </div>
  );
}
