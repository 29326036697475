interface SelectInputProps {
  values: { [label: string]: any }[];
  onChange: (value: any) => void;
  displayFormat?: (value: string) => string;
}

export default function SelectInput(props: SelectInputProps) {
  let options =
    props.values.length > 0 &&
    props.values.map((item: any, id: any) => {
      return (
        <option className="text-sky-500 text-sm" key={id} value={item.value}>
          {item.label}
        </option>
      );
    });

  return (
    <div className="h-[37px] border-sky-500 border-[1px] shadow-inner shadow-sky-100 rounded flex">
      <select
        className="w-full text-sm text-sky-500 border-none text-center shadow-inner shadow-sky-100 rounded"
        onChange={(e) => {
          props.onChange(e.target.value);
        }}
      >
        {options}
      </select>
    </div>
  );
}
