import { MinusIcon, PlusIcon } from '@heroicons/react/solid';
import { useEffect, useState } from 'react';

interface NumberInputProps {
  value: number;
  min: number;
  max?: number;
  onChange: (value: number) => void;
  displayFormat?: (value: number) => string;
}

export default function NumberInput(props: NumberInputProps) {
  const [value, setValue] = useState(props.value);
  const [editing, setEditing] = useState(false);

  function changeValue(newVal: number) {
    let num = isNaN(newVal) ? 0 : newVal;
    if (num < props.min) num = props.min;
    if (props.max && num > props.max) num = props.max;

    setValue(num);
    props.onChange(num);
  }

  function onFocus() {
    setEditing(true);
  }

  function onBlur() {
    setEditing(false);
  }

  function displayValue() {
    return editing
      ? value
      : props.displayFormat
      ? props.displayFormat(value)
      : value;
  }

  return (
    <div className="h-[34px] border-sky-500 border-[1px] shadow-inner shadow-sky-100 rounded flex">
      <button
        className="flex p-2"
        onClick={(e) => {
          e.preventDefault();
          changeValue(value - 1);
        }}
      >
        <MinusIcon className="w-3 h-3 m-auto" />
      </button>
      <input
        className="w-full text-center shadow-inner shadow-sky-100"
        value={displayValue()}
        onFocus={onFocus}
        onChange={(e) => {
          changeValue(parseInt(e.target.value));
        }}
        onBlur={onBlur}
      />
      <button
        className="flex p-2"
        onClick={(e) => {
          e.preventDefault();
          changeValue(value + 1);
        }}
      >
        <PlusIcon className="w-3 h-3 m-auto" />
      </button>
    </div>
  );
}
