import { useEffect, useState } from 'react';
import data from '../data/units.json';
import BathIcon from '../icons/bath.png';
import BedIcon from '../icons/bed.png';
import RullerIcon from '../icons/ruller.png';
import TerraceIcon from '../icons/terrace.png';
import { price } from '../util/format';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRuler, faBed, faShower } from '@fortawesome/free-solid-svg-icons';

interface UnitDescriptionProps {
  name: string;
  unitid: string;
}

export default function UnitDescription({ current }: any) {
  const [showPrice, setShowPrice] = useState<boolean>(false);

  useEffect(() => {
    setShowPrice(false);
    // setShowPriceClass(current.available !== 'FALSE');
  }, [current]);

  return (
    <>
      <div className="flex justify-between">
        <div className="flex">
          <div className="text-lg font-bold">{current.unit}</div>
          {/* <div className="w-5 h-5 m-auto ml-2">
                        <FavButton unitid={props.unitid} />
                    </div> */}
        </div>

        {showPrice && <div className={`text-lg`}>{price(current.price)}</div>}
      </div>

      <div className="text-sm w-full">
        <table className="table-fixed w-full mt-3 divide-y divide-gray">
          <tbody>
            <tr className="border-b-2 border-gray">
              <td className="w-5 py-3 mr-5">
                <FontAwesomeIcon icon={faRuler} />
              </td>
              <td className="pl-2">Wonen in m2</td>
              <td className="text-right">{current.surface}m2</td>
            </tr>
            <tr className="border-b-2 border-gray">
              <td className="w-5 py-3 mr-5">
                <img src={TerraceIcon} alt="Terras in m2"></img>
              </td>
              <td className="pl-2">Terras in m2</td>
              <td className="text-right">{current.terras}</td>
            </tr>
            <tr className="border-b-2 border-gray">
              <td className="w-5 py-3 mr-5">
                <FontAwesomeIcon icon={faBed} />
              </td>
              <td className="pl-2">Slaapkamers</td>
              <td className="text-right">{current.bedrooms}</td>
            </tr>
            <tr className="">
              <td className="w-5 py-3 mr-5">
                <FontAwesomeIcon icon={faShower} />
              </td>
              <td className="pl-2">Badkamers</td>
              <td className="text-right">{current.bathrooms}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
}
