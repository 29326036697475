import { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { Navigate, useLocation, useParams } from 'react-router-dom';
import { useOutletContext, useNavigate } from 'react-router-dom';
import axios from 'axios';
import BackButton from '../../components/BackButton';
import Gallery from '../../components/Gallery';
import PButton from '../../components/PButton';
import TextInput from '../../components/TextInput';
import UnitDescription from '../../components/UnitDescription';
import PlanIcon from '../../icons/plan.png';
import RightPanel from '../../RightPanel';
import PhotoIcon from '../../icons/photo.png';
import ThreeDIcon from '../../icons/3d.png';
import VideoIcon from '../../icons/video.png';
import { Tooltip } from 'flowbite-react';
import { Triangle } from 'react-loader-spinner';
import { isMobile } from 'react-device-detect';
import { ArrowLeftIcon } from '@heroicons/react/solid';

Modal.setAppElement('#root');

interface unitDataInterface {
  images: any;
  available: any;
  unit: any;
  kuula_link?: string;
}

function ContentButton(props: {
  children: Element | JSX.Element;
  active?: boolean;
  disabled?: boolean;
  onClick: () => void;
}) {
  return (
    <div className="w-[60px] h-[60px] flex" onClick={() => props.onClick()}>
      <div
        className={
          'w-[35px] h-[35px] m-auto hover:bg-gray-200 flex transition-all  p-1' +
          (props.active ? 'bg-gray-200 p-1' : '')
        }
      >
        <div
          className={'m-auto' + (props.disabled === true ? ' opacity-30' : '')}
        >
          {props.children}
        </div>
      </div>
    </div>
  );
}

function convertAvailableToText(input: String) {
  if (input === 'TRUE') {
    return (
      <Tooltip content={'Beschikbaar'}>
        <div className={'text-[#3BBE60] text-sm'}>Beschikbaar</div>
      </Tooltip>
    );
  }
  if (input === 'OPTION') {
    return (
      <Tooltip content={'In optie'}>
        <div className={'text-[#FFA500] text-sm'}>In optie</div>
      </Tooltip>
    );
  }
  if (input === 'FALSE') {
    return (
      <Tooltip content={'Verkocht'}>
        <div className={'text-[#FF0000] text-sm'}>Verkocht</div>
      </Tooltip>
    );
  } else {
    return input;
  }
}

function ContentSwitch(props: {
  content: string;
  onChange: (content: string) => void;
}) {
  return (
    <div className="xs:bg-slate-100 xs:w-full xl:fixed left-[24px] bottom-[24px] bg-white flex justify-center">
      <ContentButton
        active={props.content === 'gallery'}
        onClick={() => props.onChange('gallery')}
      >
        <img src={PhotoIcon} alt="" />
      </ContentButton>

      <ContentButton
        active={props.content === '3d'}
        onClick={() => props.onChange('3d')}
      >
        <img src={ThreeDIcon} alt="" />
      </ContentButton>

      {/* <ContentButton
        active={props.content === 'video'}
        onClick={() => props.onChange('video')}
      >
        <img src={VideoIcon} alt="" />
      </ContentButton> */}
      {false ? (
        <ContentButton onClick={() => null}>
          <img src={PlanIcon} alt="" />
        </ContentButton>
      ) : null}
    </div>
  );
}

export default function Unit() {
  const [project, setLoaderVisible]: any = useOutletContext();
  const { unit_id } = useParams();

  let project_id = project.project?._id;

  const [content, setContent] = useState('gallery');
  const [email, setEmail] = useState('');
  const [agreed, setAgreed] = useState(false);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<unitDataInterface>({
    images: [],
    available: 'false',
    unit: '',
  });
  const [emailAcknowledgeMessage, setEmailAcknowledgeMessage] = useState(false);
  const [rightPanelIsOpen, setRightPanelIsOpen] = useState<boolean>(false);

  const navigate = useNavigate();

  useEffect(() => {
    fetchUnit();
  }, [project]);

  const fetchUnit = async function () {
    setLoading(true);
    try {
      axios
        .get(
          `${
            process.env.REACT_APP_BASE_URL
          }/wf/units?project_id=${project_id}&unit_key=${unit_id?.toUpperCase()}`
        )
        .then((response) => {
          let tmp = response.data.response.units.filter(
            (unit: any) => unit.unit === unit_id?.toUpperCase()
          )[0];

          if (!tmp) return;

          if (!tmp?.images) {
            tmp.images = [
              'https://images.adsttc.com/media/images/5be3/3a40/08a5/e549/e300/0315/newsletter/42442.jpg?1541618191',
            ];
          }

          setData(tmp);

          setLoading(false);
          setLoaderVisible(false);
        });
    } catch (error: any) {
      setLoading(false);
    }
  };

  async function sendMail() {
    setLoading(true);

    try {
      await fetch('https://hooks.zapier.com/hooks/catch/12904687/bgbv09e/', {
        method: 'post',
        body: JSON.stringify({ email }),
      }).then((r) => r.json());

      setEmail('');
      setEmailAcknowledgeMessage(true);

      setTimeout(() => {
        setEmailAcknowledgeMessage(false);
      }, 5000);
    } catch (error) {
      console.error(error);
    }

    window.location.href =
      'mailto:info@kindtenbiesbroeck.nl?subject=' +
      'Stadshave: interesse in unit ' +
      encodeURIComponent(data.unit) +
      '&from=' +
      encodeURIComponent(email);

    setLoading(false);
  }

  if (loading) {
    return <div className="app">Loading...</div>;
  }

  return (
    <>
      <Modal isOpen={true}>
        {!isMobile && <BackButton click={() => navigate('/')} />}

        {isMobile && (
          <>
            <nav className="bg-white border-gray-200 px-2 lg:px-4 py-2.5 rounded dark:bg-gray-900  xs:block lg:hidden">
              <div className="flex flex-wrap items-center justify-between mx-auto">
                <button style={{ zIndex: 1000 }} onClick={() => navigate('/')}>
                  <div className="flex m-auto">
                    <ArrowLeftIcon className="w-6 h-6 m-auto mr-2" />
                    <div className="text-lg font-bold m-auto ml-2 animate__animated animate__fadeIn">
                      Terug
                    </div>
                  </div>
                </button>
              </div>
            </nav>
          </>
        )}

        <div className="xl:flex h-full xs:w-full xl:mr[350px]">
          <div className="grow xs:h-1/3 bg-black xs:flex xs:flex-col xl:h-full xl:mr-[350px]">
            {(function () {
              switch (content) {
                case 'gallery':
                  return data.images ? (
                    <Gallery imgs={data.images} />
                  ) : (
                    'No images provided for this unit'
                  );
                case '3d':
                  return (
                    <div className="relative w-full h-full">
                      <div className="absolute w-full h-full flex"></div>
                      <iframe
                        className="w-full h-full absolute"
                        title="3d view"
                        src={data.kuula_link}
                      />
                    </div>
                  );
                case 'video':
                  return <video className="w-full h-full" src="" autoPlay />;
                default:
                  return null;
              }
            })()}
          </div>

          {!isMobile && (
            <RightPanel
              isOpen={rightPanelIsOpen}
              setIsOpen={setRightPanelIsOpen}
            >
              <>
                <div className="p-[20px]">
                  <div>{convertAvailableToText(data.available)}</div>
                  <div className="mb-3">
                    <UnitDescription current={data} />
                  </div>

                  <div className="mt-8 pt-2 border-t-2 border-slate-300">
                    <div className="font-bold mb-3 mt-2">Neem contact op</div>
                    <TextInput
                      name="email"
                      type="email"
                      value={email}
                      onChange={(val) => setEmail(val)}
                      disabled={loading}
                    />
                    <div className="form-check inline-block">
                      <label className="form-check-label inline-block text-gray-800 cursor-pointer">
                        <div className="text-sm mb-2 mt-2 text-gray-500">
                          {emailAcknowledgeMessage ? (
                            <div className="text-green-800 font-bold bg-green-200 p-2 mb-1 text-center transition-all duration-300">
                              Email ontvangen
                            </div>
                          ) : null}
                          <input
                            className="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 align-top bg-no-repeat bg-center bg-contain mr-2 cursor-pointer"
                            type="checkbox"
                            value=""
                            id="flexCheckDefault"
                            onChange={(e) => setAgreed(e.target.checked)}
                          ></input>
                          Ik ga akkoord met de{' '}
                          <a
                            href="https://www.kindtenbiesbroeck.nl/contact/privacy-policy"
                            className="text-black underline"
                            target="_blank"
                            rel="noreferrer"
                          >
                            privacyverklaring
                          </a>{' '}
                          en erken dat mijn gegevens worden verwerkt volgens de{' '}
                          <a
                            href="https://www.kindtenbiesbroeck.nl/contact/privacy-policy"
                            className="text-black underline"
                            target="_blank"
                            rel="noreferrer"
                          >
                            privacyverklaring
                          </a>
                          .
                        </div>
                      </label>
                    </div>
                    <div className="pt-3">
                      <PButton
                        title="Ik ben geïnteresseerd"
                        onClick={sendMail}
                        disabled={!agreed || loading}
                      />
                    </div>
                  </div>
                </div>
                <div></div>
              </>
            </RightPanel>
          )}

          {isMobile && (
            <>
              <nav className="bg-white border-gray-200 xs:px-0 xs:py-0 lg:px-2 lg:px-4 lg:py-2.5 rounded dark:bg-gray-900  xs:block lg:hidden w-full">
                <div className="flex flex-wrap items-center justify-between mx-auto">
                  <ContentSwitch
                    content={content}
                    onChange={(content) => setContent(content)}
                  />
                </div>
              </nav>
              <div className="xl:hidden grow h-2/3 px-[15px] bg-white overflow-scroll">
                <div className="p-[20px]">
                  <div>{convertAvailableToText(data.available)}</div>
                  <div className="mb-3">
                    <UnitDescription current={data} />
                  </div>

                  <div className="mt-8 pt-2 border-t-2 border-slate-300">
                    <div className="font-bold mb-3 mt-2">Neem contact op</div>
                    <TextInput
                      name="email"
                      type="email"
                      value={email}
                      onChange={(val) => setEmail(val)}
                      disabled={loading}
                    />
                    <div className="form-check inline-block">
                      <label className="form-check-label inline-block text-gray-800 cursor-pointer">
                        <div className="text-sm mb-2 mt-2 text-gray-500">
                          {emailAcknowledgeMessage ? (
                            <div className="text-green-800 font-bold bg-green-200 p-2 mb-1 text-center transition-all duration-300">
                              Email ontvangen
                            </div>
                          ) : null}
                          <input
                            className="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 align-top bg-no-repeat bg-center bg-contain mr-2 cursor-pointer"
                            type="checkbox"
                            value=""
                            id="flexCheckDefault"
                            onChange={(e) => setAgreed(e.target.checked)}
                          ></input>
                          Ik ga akkoord met de{' '}
                          <a
                            href="https://www.kindtenbiesbroeck.nl/contact/privacy-policy"
                            className="text-black underline"
                            target="_blank"
                            rel="noreferrer"
                          >
                            privacyverklaring
                          </a>{' '}
                          en erken dat mijn gegevens worden verwerkt volgens de{' '}
                          <a
                            href="https://www.kindtenbiesbroeck.nl/contact/privacy-policy"
                            className="text-black underline"
                            target="_blank"
                            rel="noreferrer"
                          >
                            privacyverklaring
                          </a>
                          .
                        </div>
                      </label>
                    </div>
                    <div className="pt-3 xs:pb-[200px]">
                      <PButton
                        title="Ik ben geïnteresseerd"
                        onClick={sendMail}
                        disabled={!agreed || loading}
                      />
                    </div>
                  </div>
                </div>
                <div></div>
              </div>
            </>
          )}
        </div>
      </Modal>
    </>
  );
}
