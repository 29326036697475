import { ArrowLeftIcon } from '@heroicons/react/solid';
import { useNavigate } from 'react-router-dom';
import 'animate.css';
import { click } from '@testing-library/user-event/dist/click';

export default function BackButton(props: { to?: string; click?: any }) {
  const navigate = useNavigate();

  function onClick() {
    if (props.to) navigate(props.to);
    else if (props.click) props.click();
    else navigate(-1);
  }

  return (
    <button
      className="fixed left-[15px] top-[65px] w-[125px] h-[45px] bg-[#fdfdfd] border border-slate-100 flex"
      style={{ zIndex: 1000 }}
      onClick={onClick}
    >
      <div className="flex m-auto">
        <ArrowLeftIcon className="w-6 h-6 m-auto mr-2" />
        <div className="text-lg font-bold m-auto ml-2 animate__animated animate__fadeIn">
          Terug
        </div>
      </div>
    </button>
  );
}
